<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <SideBar class="sm:w-1/5 sm:order-first sm:mt-0 w-full order-last mt-4" />
      <div class="sm:w-4/5 w-full">
        <TitleComponent class="py-8" title="MBOM Impact Test" size="large" />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="MBOM Impact Machine Description"
              size="medium"
            />
            <a
              :href="require('../../assets/IPSeries/MBOMDetailed-min.png')"
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/MBOMDetailed-min.png"
              />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The purpose of completing impact sensitivity tests is to measure
              the sensitiveness of a material to drop-weight impact. The
              transition from no reaction to reaction must be determined to
              evaluate in-process risks. The in-process potential energy from
              impact scenarios is compared against the material response. For
              example below is shown a plot of an example transition from
              no-reaction at low impact energies to reaction at increasing
              energies. The height must be expressed in terms of energy per area
              in order to compare to in-process energies and in-process contact
              areas.
            </p>
            <a
              target="_blank"
              :href="require('../../assets/IPSeries/scurve-min.png')"
            >
              <img src="@/assets/IPSeries/scurve-min.png" />
            </a>
            <TitleComponent
              class="pt-8"
              title="Test Variations"
              size="medium"
            />
            <p class="p-light">
              There are multiple pieces of drop-weight impact equipment. The
              test equipment should be able to closely mimic in-process
              conditions and relate to the in-process energy by the expression
              of reaction probability in-terms of drop weight energy. The
              modified bureau-of-mines (MBOM) impact machine has been used by
              Hercules, ATK, SMS, and others to obtain reaction probabilities at
              various drop-weight impact energies for more than 25 years.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <thead>
                  <tr>
                    <th class="px-4 py-2">Key Parameter</th>
                    <th class="px-4 py-2">Objectives</th>
                    <th class="px-4 py-2">Origin</th>
                    <th class="px-4 py-2">Specs</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="background:#f6f6f6;">
                    <td class="border px-4 py-2 font-medium">
                      Sample condition
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Repeatably obtain results for a given sample
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      Substances are tested in the worst-case or
                      smallest-particle-size in-process condition.
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Applied impact energy (calibrated)
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Used to relate sensitivity data to in-process energies
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Historical drop height to imparted energy correlation
                      based on a 6.76 N frictional force
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                  </tr>
                  <tr style="background:#f6f6f6;">
                    <td class="border px-4 py-2 font-medium">Drop Mass</td>
                    <td class="border px-4 py-2 font-medium">
                      Source of energy
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Other drop masses may be used to simulate process
                      equipment conditions
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Mass standardized to 2.00 ± 0.05 kg
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">Drop Height</td>
                    <td class="border px-4 py-2 font-medium">
                      Source of energy
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      Typically up to 116 cm; calibration and positioning system
                      should provide minimal drop height variability
                    </td>
                  </tr>
                  <tr style="background:#f6f6f6;">
                    <td class="border px-4 py-2 font-medium">
                      Drop Mass Guides
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Minimal sliding friction (no binding) which can be
                      qualified by drop time from a specified height
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium"></td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Intermediate Mass Assembly
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Transfer energy from the drop mass into the sample through
                      the impact insert, providing a uniform, repeatable strike
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Other intermediate mass assemblies may be used that are
                      equally effective
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Standardized to 1.25 kg
                    </td>
                  </tr>
                  <tr style="background:#f6f6f6;">
                    <td class="border px-4 py-2 font-medium">
                      Impact Insert Diameter
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Provide a consistent and repeatable impact insult per area
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Other diameters may be used to simulate specific process
                      equipment conditions
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Standardized to 0.500 ± .002 in
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Anvil and Insert Surface Finish
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Provide a consistent impact insult per area
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      Standardized to 50 – 70 µin for at least two (impact
                      insert) or three (impact anvil) random locations on at
                      least two impact inserts or anvils from each grinding
                      group.
                      <router-link
                        class="link"
                        to="/library/equipment-standards-procedures"
                        >There are standardized MBOM Impact Insert and Anvil
                        Surfacing Procedures</router-link
                      >. Other surface finishes may be used to simulate specific
                      process equipment components.
                    </td>
                  </tr>
                  <tr style="background:#f6f6f6;">
                    <td class="border px-4 py-2 font-medium">
                      Material and hardness of impact insert and anvil
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Provide a standardized hardness
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Material and hardness of common process equipment tooling;
                      other materials or hardnesses may be used to simulate
                      specific process equipment components
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Standardized as MGR tool steel (A8) with a hardness of
                      Rockwell “C” 50 – 55
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Surface contact between anvil and insert
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Provide a uniform impact surface so that sensitivity data
                      can be related to in-process energies
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      A contact pattern other than a whole, full circle will
                      result in localized high and low energy points applied to
                      the sample
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Contact pattern visually verified to be a whole, full
                      circle using NCR paper, carbon paper, or equivalent;
                      otherwise the impact insert and/or anvil are replaced
                    </td>
                  </tr>
                  <tr style="background:#f6f6f6;">
                    <td class="border px-4 py-2 font-medium">
                      Sample thickness
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Replicate an in-process condition or otherwise repeatably
                      test a standardize thickness
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Other sample thicknesses may be used to simulate specific
                      process equipment conditions
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Standardized to a monolayer of material; thickness of
                      slices are typically 0.033 ± 0.004 in
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Sample quantity
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Provide a uniform distribution of material so that energy
                      is not imparted to a localized area
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Insufficient quantity to cover the entire impact insert
                      will result in uncharacteristically high energies applied
                      to the sample
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Place enough material on the anvil to cover an area in
                      excess of the 0.2 square inch area of the impact insert;
                      slices to have a minimum edge length of 5/8 in
                    </td>
                  </tr>
                  <tr style="background:#f6f6f6;">
                    <td class="border px-4 py-2 font-medium">
                      Test Temperature and Humidity
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Determine the temperature/ humidity dependence of the
                      sample’s sensitivity as applicable. Energetics typically
                      become more sensitive with an increase in temperature.
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      Standardized as ambient temperature and humidity.
                      In-process conditions should be used as possible.
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Reaction Detection
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Observation, detection, and documentation of a material’s
                      response to the test stimuli
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      High-speed video, gas analyzer
                    </td>
                  </tr>
                  <tr style="background:#f6f6f6;">
                    <td class="border px-4 py-2 font-medium">
                      Characterization of reaction types
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Provide consistent standards for judgment and evaluation
                      of a material
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      Flash/ flame, audible report, smoke, flame trace,
                      significant sample consumption, decomposition (change in
                      color or odor), jetting
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Number of trials
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Adequately determine the sensitivity result
                      (characterization curve) with the fewest number of trials
                    </td>
                    <td class="border px-4 py-2 font-medium"></td>
                    <td class="border px-4 py-2 font-medium">
                      At least 30 trials
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr style="background:#f6f6f6;">
                    <td class="border px-4 py-2 font-medium">
                      Reaction upon insult
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Detection of explosion (high-speed video, gas analysis,
                      etc.)
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      The trial is considered “+” at the ONSET of material
                      reaction.
                    </td>
                  </tr>
                  <tr>
                    <td class="border px-4 py-2 font-medium">
                      Transition probability
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Transition probability most efficiently defined by use of
                      an algorithm such as SEQ, Neyer, or other step sized
                      optimized method
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Risk is assessed based on the comparison of the in-process
                      energy to the material response energy.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full p-10">
            <TitleComponent
              class="pt-8"
              title="Impact Setup Example"
              size="medium"
            />
            <a
              :href="require('../../assets/IPSeries/setup-min.jpg')"
              target="_blank"
            >
              <img
                style="width:60%;"
                class="m-auto"
                src="@/assets/IPSeries/setup-min.jpg"
              />
            </a>
          </div>
          <div class="w-full md:w-1/2 md:pr-2">
            <TitleComponent
              class="pt-8"
              title="Example Video of Flash and Audible Report (+)"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166129009?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166129009"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
          </div>
          <div class="w-full md:w-1/2 md:pl-2">
            <TitleComponent
              class="pt-8 mb-10"
              title="Example Video of Jetting (+)"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166226532?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166226532"
              allow="autoplay; fullscreen"
              id="player_2"
              name="fitvid1"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/IPSeriesSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
